
import Editor, { EditorTextChangeEvent } from 'primevue/editor'
import { computed, defineComponent, onMounted, ref } from 'vue'

export default defineComponent({
  name: 'RestrictedHtmlEditor',
  components: { Editor },
  emits: [
    'update:modelValue'
  ],
  props: {
    modelValue: {
      type: String,
      required: true
    },
    maxLength: {
      type: Number,
      required: false,
      default: 0
    }
  },
  setup (props, { emit }) {
    const editableHtml = computed({
      get (): string {
        return props.modelValue
      },
      set (html: string) {
        emit('update:modelValue', html)
      }

    })
    const editorRef = ref()
    const textValue = ref<string|null>(null)
    const onUpdate = (ev:EditorTextChangeEvent) => {
      if (props.maxLength && (ev.textValue.length > props.maxLength)) {
        editorRef.value.quill.history.undo()
      } else {
        textValue.value = ev.textValue
      }
    }

    onMounted(() => {
      textValue.value = editorRef.value.quill.getText().trim()
    })

    return {
      editableHtml,
      onUpdate,
      textValue,
      editorRef
    }
  }
})
