
import { getBannerById, getBanners, removeBanner as removeBannerApi, saveBanner } from '@/api/site-banners.api'
import SiteBannerPreview from '@/components/www/SiteBannerPreview.vue'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
import { SiteBanner, SiteBannerContext } from '@/models/site-banners/SiteBanner'
import EditBannerDialog from '@/components/www/EditBannerDialog.vue'
import { FilterMatchMode } from 'primevue/api'
import DataTable from 'primevue/datatable'
import { useToast } from 'primevue/usetoast'
import { defineComponent, onMounted, Ref, ref } from 'vue'
import { useRouter } from 'vue-router'
import SelectButton from 'primevue/selectbutton'
import { getCategories } from '@/api/product-categories.api'
import { getProducts } from '@/api/products.api'
import { ProductCategory } from '@/models/www/ProductCategory'
import { Product } from '@/models/www/Products'
const emptySiteBanner: () => SiteBanner = () => ({
  id: '',
  name: '',
  content: 'Ingrese el texto',
  enabled: false,
  priority: 0,
  countryCodes: [],
  active: {
    from: new Date(),
    to: new Date()
  },
  isHeroBanner: false,
  heading: '',
  subheading: '',
  description: '',
  style: {
    color: '#000000',
    backgroundColor: '#FFFFFF'
  },
  showCountdown: false,
  cta: {
    type: 'none',
    text: '',
    url: ''
  },
  context: SiteBannerContext.WWW,
  image: '',
  categories: [],
  courses: [],
  roles: []
})

export default defineComponent({
  name: 'SiteBannersListView',
  components: {
    EditBannerDialog,
    SiteBannerPreview,
    DataTable,
    SelectButton
  },
  setup () {
    const banners = ref<Array<SiteBanner>>([])
    const editingBanner = ref<SiteBanner>(emptySiteBanner())
    const expandedRows = ref<Array<SiteBanner>>([])
    const loading = ref<boolean>(false)
    const selectedRows = ref<Array<SiteBanner>>([])
    const showEditDialog = ref<boolean>(false)
    const showPreview = ref<boolean>(false)
    const toast = useToast()
    const selectedContext = ref<SiteBannerContext>(SiteBannerContext.ALL)
    const allContexts = [
      { label: 'Todos', value: null },
      { label: 'WWW', value: SiteBannerContext.WWW },
      { label: 'Plataforma', value: SiteBannerContext.PLATFORMS }
    ]
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      context: { value: null, matchMode: FilterMatchMode.EQUALS }
    })
    const categories: Ref<ProductCategory[]> = ref([])
    const products: Ref<Product[]> = ref([])
    const switchPreview = () => {
      showPreview.value = !showPreview.value

      if (showPreview.value) {
        expandedRows.value = banners.value.filter(p => p.id)
      } else {
        expandedRows.value = []
      }
    }

    const addNew = () => {
      editingBanner.value = emptySiteBanner()

      showEditDialog.value = true
    }

    const removeBanner = async (banner: SiteBanner) => {
      try {
        const res = await removeBannerApi(banner)
        await updateBannerList()
        if (res) {
          toast.add({
            summary: 'Banner eliminado',
            detail: `El banner ${banner.name} ha sido eliminado`,
            severity: ToastSeverities.Success,
            life: ToastLife.Default
          })
        } else throw new Error('Ha ocurrido un error en el flujo de eliminación')
      } catch (e) {
        toast.add({
          summary: 'No se pudo eliminar el banner',
          severity: ToastSeverities.Error,
          life: ToastLife.Default
        })
      }
    }

    const onRemove = async (banner: SiteBanner) => {
      const key = window.prompt('Para eliminar el banner, escribe "confirmar" ', '')
      if (!key || key.toLowerCase() !== 'confirmar') return
      await removeBanner(banner)
    }

    const onRemoveSelection = () => {
      const key = window.prompt(`Para eliminar los ${selectedRows.value.length} banners, escribe "confirmar" `, '')
      if (!key || key.toLowerCase() !== 'confirmar') return
      selectedRows.value.forEach(banner => removeBanner(banner))
    }

    const onEdit = async (banner: SiteBanner) => {
      const readBanner = await getBannerById(banner.id)
      if (readBanner) {
        editingBanner.value = readBanner
        showEditDialog.value = true
      }
    }

    const onOpenTest = async (banner: SiteBanner) => {
      const host = process.env.WWW_ENDPOINT || 'https://www.coderhouse.com'
      const bannerUriComponent = encodeURIComponent(JSON.stringify(banner))
      window.open(`${host}?force-banner=${bannerUriComponent}`, '_blank')
    }

    const onCancelEditDialog = () => {
      showEditDialog.value = false
    }

    const onDuplicate = async (data: SiteBanner) => {
      const newBanner = data
      newBanner.enabled = false
      newBanner.id = ''
      if (!window.confirm('¿Desea duplicar el banner?')) return
      onSaveBanner(newBanner)
    }

    async function updateBannerList (): Promise<void> {
      loading.value = true
      banners.value = await getBanners()
      loading.value = false
    }

    const onSaveBanner = async (banner: SiteBanner) => {
      try {
        await saveBanner(banner)
        showEditDialog.value = false
        await updateBannerList()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        toast.add({
          summary: 'Error al grabar el banner',
          detail: e.message,
          severity: ToastSeverities.Error,
          life: ToastLife.Default
        })
      }
    }

    const updateCategories = async () => {
      loading.value = true
      // ---------------------
      categories.value = await getCategories()
      // ---------------------
      loading.value = false
    }

    const updateProducts = async () => {
      loading.value = true
      // ---------------------
      const { remotes, careers } = await getProducts({ all: true })
      products.value = [...remotes, ...careers]
      // ---------------------
      loading.value = false
    }

    const updateDeps = async () => {
      await updateBannerList()
      await updateProducts()
      await updateCategories()
    }

    onMounted(async () => {
      await updateDeps()
    })

    return {
      selectedContext,
      allContexts,
      SiteBannerContext,
      addNew,
      banners,
      editingBanner,
      expandedRows,
      filters,
      loading,
      onCancelEditDialog,
      onDuplicate,
      onEdit,
      onOpenTest,
      onRemove,
      onRemoveSelection,
      onSaveBanner,
      selectedRows,
      showEditDialog,
      showPreview,
      switchPreview,
      categories,
      products
    }
  }
})
