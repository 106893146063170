
import { SiteBanner } from '@/models/site-banners/SiteBanner'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'SiteBannerPreview',
  props: {
    banner: {
      type: Object as PropType<SiteBanner>,
      required: true
    }
  }
})
