import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2dee2b20")
const _hoisted_1 = ["src"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 1,
  class: "p-col-2"
}
const _hoisted_4 = {
  key: 2,
  class: "p-col-2"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: "banner-container p-grid",
    style: _normalizeStyle(_ctx.banner?.style || {})
  }, [
    (_ctx.banner.image)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "banner-image",
          src: _ctx.banner.image
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      innerHTML: _ctx.banner.content,
      class: "banner-html p-col"
    }, null, 8, _hoisted_2),
    (_ctx.banner.cta?.type === 'button')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Button, {
            label: _ctx.banner.cta.text || 'MISSING CTA TEXT'
          }, null, 8, ["label"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.banner.cta?.type === 'link')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("a", {
            href: "#",
            "onClick:": _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
          }, _toDisplayString(_ctx.banner.cta.text || 'MISSING CTA TEXT'), 33)
        ]))
      : _createCommentVNode("", true)
  ], 4))
}