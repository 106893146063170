import { Api } from '@/api/api'
import { SiteBanner } from '@/models/site-banners/SiteBanner'

const baseURL = process.env.ACADEMIC_API_ENDPOINT

async function getBanners(): Promise<SiteBanner[]> {
  return Api.get<SiteBanner[]>(`${baseURL}/v1/site-banners?context=all`)
}

async function getBannerById(id: string): Promise<SiteBanner | null> {
  return Api.get<SiteBanner | null>(`${baseURL}/v1/site-banners/${id}`)
}

async function saveBanner(siteBanner: SiteBanner): Promise<SiteBanner> {
  if (siteBanner.id) {
    return Api.patch<SiteBanner>(
      `${baseURL}/v1/site-banners/${siteBanner.id}`,
      siteBanner
    )
  }

  return Api.post<SiteBanner>(`${baseURL}/v1/site-banners`, siteBanner)
}

async function removeBanner(siteBanner: SiteBanner): Promise<SiteBanner> {
  return Api.del<SiteBanner>(
    `${baseURL}/v1/site-banners/${siteBanner.id}?version=${siteBanner.__v}`
  )
}

export { getBannerById, getBanners, saveBanner, removeBanner }
