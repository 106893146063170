
import CountrySelector from '@/components/www/CountrySelector.vue'
import RestrictedHtmlEditor from '@/components/www/RestrictedHtmlEditor.vue'
import SiteBannerPreview from '@/components/www/SiteBannerPreview.vue'
import { SiteBanner, SiteBannerContext, SiteBannerRoles } from '@/models/site-banners/SiteBanner'
import Calendar from 'primevue/calendar'
import Checkbox from 'primevue/checkbox'
import ColorPicker from 'primevue/colorpicker'
import Dialog from 'primevue/dialog'
import InputMask from 'primevue/inputmask'
import SelectButton from 'primevue/selectbutton'
import FileUpload from 'primevue/fileupload'
import MultiSelect from 'primevue/multiselect'
import { ProductCategory } from '@/models/www/ProductCategory'
import { Product } from '@/models/www/Products'
import { saveWwwImage } from '../../api/www.api'
import { computed, defineComponent, PropType, reactive } from 'vue'
const contentMaxLength = 300
const ctaTypes = [
  {
    label: 'Ninguno',
    value: 'none'
  },
  {
    label: 'Botón',
    value: 'button'
  },
  {
    label: 'Enlace',
    value: 'link'
  }
]

const contextOptions = [
  {
    label: 'WWW',
    value: SiteBannerContext.WWW
  },
  {
    label: 'Plataforma',
    value: SiteBannerContext.PLATFORMS
  }
]

const rolesOptions = [
  {
    label: 'Profesor',
    value: SiteBannerRoles.TEACHER
  },
  {
    label: 'Tutor Regular',
    value: SiteBannerRoles.ASSISTANT
  },
  {
    label: 'Tutor Adjunto',
    value: SiteBannerRoles.ASSOCIATEASSISTANT
  },
  {
    label: 'Tutor CoderAsk o Tutor Chat CoderAsk',
    value: SiteBannerRoles.CODERASKASSISTANT
  },
  {
    label: 'Tutor Sincronico',
    value: SiteBannerRoles.SYNCASSISTANT
  },
  {
    label: 'Tutor Corrector',
    value: SiteBannerRoles.REVIEWERASSISTANT
  },
  {
    label: 'Coordinador',
    value: SiteBannerRoles.COORDINATOR
  },
  {
    label: 'Estudiante',
    value: SiteBannerRoles.STUDENT
  }
]

const toQuillHtml = (text: string) => {
  return text
    .replaceAll('banner-center', 'ql-align-center')
    .replaceAll('banner-left', 'ql-align-left')
    .replaceAll('banner-right', 'ql-align-right')
}

const fromQuillHtml = (html: string) => {
  return html
    .replaceAll('ql-align-center', 'banner-center')
    .replaceAll('ql-align-left', 'banner-left')
    .replaceAll('ql-align-right', 'banner-right')
}

export default defineComponent({
  name: 'EditBannerDialog',
  components: { ColorPicker, RestrictedHtmlEditor, CountrySelector, Dialog, InputMask, SelectButton, SiteBannerPreview, Calendar, Checkbox, FileUpload, MultiSelect },
  emits: [
    'onCancel',
    'onSave',
    'onOpenTest'
  ],
  props: {
    banner: {
      type: Object as PropType<SiteBanner>,
      required: true
    },
    show: {
      type: Boolean,
      required: true
    },
    categories: {
      type: Object as PropType<ProductCategory[]>,
      required: true
    },
    products: {
      type: Object as PropType<Product[]>,
      required: true
    }
  },
  setup (props, { emit }) {
    const editableBanner = reactive(props.banner)

    editableBanner.active.from = new Date(editableBanner.active.from)
    editableBanner.active.to = new Date(editableBanner.active.to)

    const content = computed({
      get (): string {
        return toQuillHtml(editableBanner.content)
      },
      set (quillHtml: string) {
        editableBanner.content = fromQuillHtml(quillHtml)
      }
    })

    const visible = computed({
      get () {
        return props.show
      },
      set () {
        emit('onCancel')
      }
    })

    const validData = computed(() => !!(editableBanner.name.trim()) && !!(editableBanner.content.trim()))

    const onSave = () => {
      emit('onSave', editableBanner)
    }

    const closeEditDialog = () => {
      console.log('Clicked cancel')
      emit('onCancel')
    }

    const openTestUrl = () => {
      emit('onOpenTest', editableBanner)
    }

    const onUploaded = (xhr: XMLHttpRequest, files: any) => {
      console.log({ xhr, files })
    }

    const onUpload = async (event: any) => {
      console.log({ files: event.files, file: event.files[0] })
      const response = await saveWwwImage(event.files[0])
      editableBanner.image = response.url
    }

    return {
      editableBanner,
      closeEditDialog,
      openTestUrl,
      content,
      ctaTypes,
      contextOptions,
      onSave,
      visible,
      validData,
      SiteBannerContext,
      contentMaxLength,
      onUploaded,
      onUpload,
      rolesOptions
    }
  }
})
