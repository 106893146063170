export enum SiteBannerContext {
  ALL = 'all',
  WWW = 'www',
  PLATFORMS = 'platform',
}

export enum SiteBannerRoles {
  TEACHER = 'teacher',
  ASSISTANT = 'assistant',
  ASSOCIATEASSISTANT = 'associateAssistant',
  CODERASKASSISTANT = 'coderAskAssistant',
  SYNCASSISTANT = 'syncAssistant',
  REVIEWERASSISTANT = 'reviewerAssistant',
  COORDINATOR ='coordinator',
  STUDENT = 'student'
}
export interface SiteBanner {
  id: string,
  name: string,
  content: string,
  enabled: boolean,
  priority: number,
  countryCodes: Array<string>,
  isHeroBanner: boolean,
  heading: string,
  subheading: string,
  description: string,
  active: {
    from: Date,
    to: Date
  },
  style?: {
    color?: string,
    backgroundColor?: string
  },
  showCountdown: boolean,
  cta: {
    type: 'none' | 'button' | 'link',
    text: string
    url: string
  },
  context: SiteBannerContext,
  categories?: Array<string>,
  courses?: Array<string>,
  roles?: Array<SiteBannerRoles>,
  image?: string,
  __v?: number,
}
